import React from "react"
import { Link } from "gatsby"
import { css } from "@emotion/core"
import Layout from "../components/layoutMainCustom"
import BgImage from "../images/visit.jpg"
import SEO from "../components/seo"
import PageHeaders from "../components/pageHeaders"

const IndexPage = () => (
  <Layout
    ImgSrc={BgImage}
    MainText="Visit Us"
    SecondText="Let us know you're coming."
  >
    <SEO title="Plan a Visit - Cross Church" />
    <PageHeaders
      css={css`
        display: flex;
      `}
    >
      Visit Cross Church
    </PageHeaders>
    <iframe
      src="https://cccares.formstack.com/forms/planavisit"
      title="Plan a Visit"
      width="100%"
      height="1500px"
      frameBorder="0"
    ></iframe>
  </Layout>
)

export default IndexPage
